@import "./variables";
@import "./mediaquery";

.login-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: top;
  z-index: -1; // Ensure it stays behind the login form
  transition: background-image 1s ease-in-out;
}
